var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "nvSnapshotOverview" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _vm.isInstanceObserver
            ? _c(
                "v-banner",
                {
                  staticClass: "mb-5",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function () {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  target: "_blank",
                                  color: "primary",
                                  loading: _vm.requestingRole,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitRequest(
                                      _vm.invitationAndRequestRoleTypes
                                        .INSTANCE_ROLE,
                                      _vm.roleCodes.INSTANCE_VIEWER
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Request role ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2551848833
                  ),
                },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: { slot: "icon", color: "white", size: "30" },
                      slot: "icon",
                    },
                    [
                      _c("v-icon", { attrs: { large: "", color: "info" } }, [
                        _vm._v("info"),
                      ]),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "subtitle-1" }, [
                    _vm._v(
                      " You have an observer role in this space which does not allow you to work with its content. To gain access please request a viewer role. "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.privateInstances.length >
            _vm.tours.PRESTART_MINIMUM_INSTANCE_COUNT &&
          _vm.schedules.length === 0 &&
          !_vm.currentSpaceArchived &&
          _vm.isSpaceAdmin &&
          _vm.isMasterInstance &&
          _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE
            ? _c(
                "v-banner",
                {
                  staticClass: "mb-5",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function () {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  to: {
                                    name: "snapshot-applications",
                                  },
                                  color: "primary",
                                },
                              },
                              [_vm._v(" Go to apps for scheduling ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1818868477
                  ),
                },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: { slot: "icon", color: "white", size: "30" },
                      slot: "icon",
                    },
                    [
                      _c("v-icon", { attrs: { large: "", color: "info" } }, [
                        _vm._v("info"),
                      ]),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "subtitle-1" }, [
                    _vm._v(
                      " No upcoming scheduled application starts defined. For optimal application start speed, please specify all known times when many students are expected to start working on Nuvolos simultaneously. "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          !_vm.fetchingInstances &&
          _vm.currentSpaceArchived &&
          !_vm.isMasterInstance &&
          !_vm.isSpaceInAnyRestState
            ? _c(
                "v-alert",
                { attrs: { text: "", color: "info" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "info", right: "" } },
                            [_vm._v("info")]
                          ),
                        ],
                        1
                      ),
                      _c("v-col", { staticClass: "grow" }, [
                        _vm.isDevelopment &&
                        _vm.snapshotFilesystemPrefix &&
                        _vm.instanceRearchiveAfterTimestamp
                          ? _c("span", [
                              _vm._v(
                                " This space has been archived. Contents of Current State are available for temporary access but will be permanently deleted after " +
                                  _vm._s(
                                    _vm._f("dateTimeToHuman")(
                                      _vm.instanceRearchiveAfterTimestamp
                                    )
                                  ) +
                                  ". "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                " This instance has been archived. Contents may be restored for temporary access but prolonged active use is not supported. "
                              ),
                            ]),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                href: "https://docs.nuvolos.cloud/user-guides/education-guides/archiving-your-course",
                                target: "_blank",
                                color: "primary",
                              },
                            },
                            [_vm._v(" Learn more ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isSpaceAdmin &&
          _vm.archivalWithinThirtyDays &&
          !_vm.currentSpaceArchived
            ? _c(
                "v-alert",
                { attrs: { text: "", color: "info" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "info", right: "" } },
                            [_vm._v("info")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "grow" },
                        [
                          _vm._v(" This space is scheduled to be archived on "),
                          _c("strong", [_vm._v(_vm._s(_vm.spaceArchivalDate))]),
                          _vm._v(
                            " . You can set a new archival date in the space's details using the "
                          ),
                          _c("v-icon", { attrs: { dense: "" } }, [
                            _vm._v("info"),
                          ]),
                          _vm._v(" icon below. "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex flex-column" },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _vm.isSpaceAdmin && !_vm.isSpaceNameFieldMounted
                    ? _c(
                        "span",
                        {
                          staticClass: "subtitle-2 secondary--text",
                          attrs: { type: "list-item" },
                        },
                        [_vm._v("Loading space name....")]
                      )
                    : _vm._e(),
                  _vm.spaceLongName && _vm.isSpaceAdmin
                    ? _c("editable-area", {
                        attrs: {
                          value: _vm.spaceLongName,
                          apiURL: "/spaces/" + this.$route.params.sid,
                          successMessage: "Space name successfully updated!",
                          editAttr: "long_id",
                          fontClass: "title font-weight-bold",
                          editAttrFullName: "space name",
                          defaultValue: "Space name not available",
                          color: "white",
                          showEditIcon:
                            _vm.isSpaceAdmin &&
                            !_vm.isSpaceRested &&
                            !_vm.isSpaceResting,
                          id: parseInt(_vm.$route.params.sid, 10),
                          finally: () => {
                            this.$store.dispatch(
                              "orgStore/fetchOrgSpaces",
                              this.$route.params.oid
                            )
                          },
                        },
                        on: {
                          mounted: function ($event) {
                            _vm.isSpaceNameFieldMounted = true
                          },
                        },
                      })
                    : !_vm.isSpaceAdmin
                    ? _c(
                        "span",
                        {
                          staticClass: "secondary--text title font-weight-bold",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$store.getters[
                                  "spaceStore/spaceLongNameById"
                                ](_vm.$route.params.sid)
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c("span", [_vm._v("Loading space name...")]),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      small: "",
                                      text: "",
                                      icon: "",
                                      disabled:
                                        _vm.isSpaceRested || _vm.isSpaceResting,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.detailsDrawer = true
                                      },
                                    },
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("info")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Show details")])]
                  ),
                ],
                1
              ),
              _vm.isSpaceAdmin && !_vm.isSpaceNameFieldMounted
                ? _c("span", { attrs: { type: "list-item" } }, [
                    _vm._v("Loading space description...."),
                  ])
                : _vm._e(),
              _vm.isSpaceAdmin
                ? _c("editable-area", {
                    attrs: {
                      value: _vm.spaceDescription,
                      apiURL: "/spaces/" + this.$route.params.sid,
                      successMessage: "Space description successfully updated!",
                      editAttr: "description",
                      editAttrFullName: "space description",
                      color: "white",
                      defaultValue: "Description not available for this space",
                      showEditIcon:
                        _vm.isSpaceAdmin &&
                        !_vm.isSpaceRested &&
                        !_vm.isSpaceResting,
                      id: parseInt(_vm.$route.params.sid, 10),
                      finally: () => {
                        this.$store.dispatch(
                          "orgStore/fetchOrgSpaces",
                          this.$route.params.oid
                        )
                      },
                    },
                    on: {
                      mounted: function ($event) {
                        _vm.isSpaceDescriptionFieldMouted = true
                      },
                    },
                  })
                : _c("span", { staticClass: "subtitle-2 secondary--text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$store.getters["spaceStore/spaceDescriptionById"](
                          this.$route.params.sid
                        )
                      )
                    ),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.bundleCreated
        ? _c(
            "v-alert",
            { attrs: { text: "", prominent: "", type: "info" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-col", { staticClass: "grow" }, [
                    _c("div", { staticClass: "font-weight-bold" }, [
                      _vm._v("Did you know?"),
                    ]),
                    _c("div", [
                      _vm._v(
                        "You can recall distributed files easily if needed."
                      ),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            href: "https://docs.nuvolos.cloud/features/object-distribution#recall-files",
                            target: "_blank",
                            outlined: "",
                            small: "",
                            color: "info",
                          },
                        },
                        [_vm._v(" Learn more ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.assignmentsList.length
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                _vm._l(_vm.assignmentsList, function (assignment, idx) {
                  return _c(
                    "v-col",
                    { key: idx, attrs: { xs: "12", md: "4", xl: "3" } },
                    [
                      _c("TheSnapshotAssignmentTile", {
                        attrs: { assignmentData: assignment },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c(
                    "v-card",
                    {
                      staticStyle: { "min-height": "430px" },
                      attrs: {
                        color:
                          _vm.readmeMdWithDefault === "" && !_vm.fetchingReadme
                            ? "white"
                            : "",
                        disabled: _vm.isSpaceResting || _vm.isSpaceWaking,
                      },
                    },
                    [
                      _c("v-card-text", [
                        _vm.fetchingReadme
                          ? _c(
                              "div",
                              [
                                _c("v-skeleton-loader", {
                                  attrs: { type: "article@2" },
                                }),
                                _c("v-skeleton-loader", {
                                  staticClass: "ml-4",
                                  attrs: { type: "paragraph" },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    },
                                  },
                                  [
                                    _vm.isDevelopment &&
                                    _vm.isInstanceEditor &&
                                    !_vm.isSpaceRested
                                      ? _c("the-snapshot-file-editor", {
                                          attrs: {
                                            isMarkdownFile: true,
                                            isCreateReadmeFile:
                                              _vm.readmeData === null,
                                            endpoint: `/snapshots/${_vm.$route.params.snid}/fs/files`,
                                            fileData: _vm.readmeData || {},
                                            language: "markdown",
                                            newReadmeFileContent:
                                              _vm.newReadmeFileContent,
                                          },
                                          on: {
                                            readmeFileUpdated: function (
                                              $event
                                            ) {
                                              return _vm.fetchReadmeAndVideos(
                                                _vm.$route.params.snid
                                              )
                                            },
                                            readmeFileCreated: function (
                                              $event
                                            ) {
                                              return _vm.fetchReadmeAndVideos(
                                                _vm.$route.params.snid
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.failedToFetchReadme
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center text-center justify-center align-center",
                                        staticStyle: { "min-height": "400px" },
                                      },
                                      [
                                        _vm.isDevelopment
                                          ? [
                                              _vm.isInstanceArchived
                                                ? _c("p", [
                                                    _vm._v(
                                                      " This instance has been archived so there are no files in its Current State. Please "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.goToSnapshots()
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("restore")]
                                                    ),
                                                    _vm._v(
                                                      " a snapshot to the Current State to work with files. "
                                                    ),
                                                  ])
                                                : _c("div", [
                                                    _vm._v(
                                                      "Failed to fetch files for this instance, please contact support."
                                                    ),
                                                  ]),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm.readmeMdWithDefault
                                  ? _c("MarkdownViewer", {
                                      attrs: {
                                        editorText: _vm.readmeMdWithDefault,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.showSidebarLoader
                ? _c(
                    "v-col",
                    { attrs: { md: "4" } },
                    [
                      _vm.showCheckList
                        ? [
                            _c("TheSnapshotCourseCheckList", {
                              attrs: { schedules: _vm.schedules },
                              on: {
                                mounted: function ($event) {
                                  _vm.courseChecklistMounted = true
                                },
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm.videoURLs
                        ? _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c("v-divider", { staticClass: "my-3" }),
                              _c("v-card-title", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "h5",
                                      { staticClass: "secondary--text" },
                                      [_vm._v("Videos")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          small: "",
                                          text: "",
                                        },
                                        on: { click: _vm.showVideoModal },
                                      },
                                      [_vm._v("See All")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("v-card-text", { staticClass: "pa-0" }, [
                                _c("div", { attrs: { id: "nvVideoPreview" } }, [
                                  _c("iframe", {
                                    directives: [
                                      {
                                        name: "resize",
                                        rawName: "v-resize",
                                        value: _vm.onResize,
                                        expression: "onResize",
                                      },
                                    ],
                                    style: {
                                      width: "100%",
                                      "background-color": "black",
                                      height: _vm.videoHeight + "px",
                                    },
                                    attrs: {
                                      src: _vm.videoURLs[
                                        _vm.videoURLs.length - 1
                                      ].url,
                                      frameborder: "0",
                                      allow: "autoplay; fullscreen",
                                      allowfullscreen: "",
                                    },
                                  }),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.showCheckList && !_vm.isInstanceObserver
                        ? [
                            _c(
                              "v-card",
                              { attrs: { flat: "", color: "transparent" } },
                              [
                                _c("v-card-title", [
                                  _c("h5", { staticClass: "secondary--text" }, [
                                    _vm._v("Resources"),
                                  ]),
                                ]),
                                _c(
                                  "v-card-text",
                                  { staticClass: "pa-0" },
                                  [
                                    _c(
                                      "v-list",
                                      {
                                        staticClass: "pa-0",
                                        attrs: {
                                          nav: "",
                                          color: "transparent",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              to: {
                                                name: "snapshot-files",
                                                params: {
                                                  oid: _vm.$route.params.oid,
                                                  sid: _vm.$route.params.sid,
                                                  iid: _vm.$route.params.iid,
                                                  snid: _vm.$route.params.snid,
                                                  fileArea: _vm.fileAreaType,
                                                  localPath:
                                                    _vm.currentFileLocalPath,
                                                },
                                              },
                                              "two-line":
                                                _vm.screenSize === "large" ||
                                                _vm.screenSize === "small",
                                              "three-line":
                                                _vm.screenSize === "medium",
                                              "e2e-snapshot-resources-files":
                                                "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-avatar",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { large: "" } },
                                                  [_vm._v("folder")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "mr-2 secondary--text",
                                                    },
                                                    [_vm._v("Files")]
                                                  ),
                                                ]),
                                                _c("v-list-item-subtitle", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "caption mr-3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Browse and manage files"
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              to: {
                                                name: "snapshot-tables",
                                                params: {
                                                  oid: _vm.$route.params.oid,
                                                  sid: _vm.$route.params.sid,
                                                  iid: _vm.$route.params.iid,
                                                  snid: _vm.$route.params.snid,
                                                },
                                              },
                                              "two-line":
                                                _vm.screenSize === "large" ||
                                                _vm.screenSize === "small",
                                              "three-line":
                                                _vm.screenSize === "medium",
                                              "e2e-snapshot-resources-tables":
                                                "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-avatar",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { large: "" } },
                                                  [_vm._v("mdi-table-large")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "mr-2 secondary--text",
                                                    },
                                                    [_vm._v("Tables")]
                                                  ),
                                                ]),
                                                _c("v-list-item-subtitle", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "caption mr-3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Query and export data"
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              to: {
                                                name: "snapshot-applications",
                                                params: {
                                                  oid: _vm.$route.params.oid,
                                                  sid: _vm.$route.params.sid,
                                                  iid: _vm.$route.params.iid,
                                                  snid: _vm.$route.params.snid,
                                                },
                                              },
                                              "two-line":
                                                _vm.screenSize === "large" ||
                                                _vm.screenSize === "small",
                                              "three-line":
                                                _vm.screenSize === "medium",
                                              "e2e-snapshot-resources-applications":
                                                "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-avatar",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { large: "" } },
                                                  [_vm._v("desktop_windows")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "mr-2 secondary--text",
                                                    },
                                                    [_vm._v("Applications")]
                                                  ),
                                                ]),
                                                _c("v-list-item-subtitle", [
                                                  _c(
                                                    "span",
                                                    { staticClass: "caption" },
                                                    [
                                                      _vm._v(
                                                        "Create and launch applications"
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              to: {
                                                name: "instance-snapshots",
                                                params: {
                                                  oid: _vm.$route.params.oid,
                                                  sid: _vm.$route.params.sid,
                                                  iid: _vm.$route.params.iid,
                                                  snid: _vm.$route.params.snid,
                                                },
                                              },
                                              "two-line":
                                                _vm.screenSize === "large" ||
                                                _vm.screenSize === "small",
                                              "three-line":
                                                _vm.screenSize === "medium",
                                              "e2e-snapshot-resources-snapshots":
                                                "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-avatar",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { large: "" } },
                                                  [_vm._v("camera_alt")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "mr-2 secondary--text",
                                                    },
                                                    [_vm._v("Snapshots")]
                                                  ),
                                                ]),
                                                _c("v-list-item-subtitle", [
                                                  _vm.currentSpaceType ===
                                                  _vm.spaceTypes.EDUCATION_SPACE
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Review timeline of saved course versions "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm.currentSpaceType ===
                                                      _vm.spaceTypes
                                                        .RESEARCH_SPACE
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Review timeline of saved project versions "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.currentSpaceType ===
                                                  _vm.spaceTypes.VENDOR_SPACE
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Review timeline of saved dataset versions "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("v-skeleton-loader", {
                                attrs: { type: "article@2" },
                              }),
                              _c("v-skeleton-loader", {
                                staticClass: "ml-4",
                                attrs: { type: "paragraph" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "60vw" },
          model: {
            value: _vm.showVideos,
            callback: function ($$v) {
              _vm.showVideos = $$v
            },
            expression: "showVideos",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Videos")]),
              _c(
                "v-card-text",
                [
                  _vm.videoURLs
                    ? _c(
                        "v-carousel",
                        {
                          attrs: { height: "55vh", "hide-delimiters": "" },
                          model: {
                            value: _vm.videoModel,
                            callback: function ($$v) {
                              _vm.videoModel = $$v
                            },
                            expression: "videoModel",
                          },
                        },
                        _vm._l(_vm.videoURLs, function (video, i) {
                          return _c("v-carousel-item", { key: video.url }, [
                            _c("div", { staticClass: "mb-2 secondary--text" }, [
                              _vm._v(_vm._s(video.description)),
                            ]),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("iframe", {
                                  style: {
                                    width: "50vw",
                                    "background-color": "black",
                                    height: "50vh",
                                  },
                                  attrs: {
                                    src: _vm.videoURLs[i].url,
                                    frameborder: "0",
                                    allow: "autoplay; fullscreen",
                                    allowfullscreen: "",
                                  },
                                }),
                              ]
                            ),
                          ])
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            fixed: "",
            right: "",
            temporary: "",
            touchless: "",
            width: _vm.detailsDrawerWidth,
          },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function () {
                return [
                  _c("v-divider"),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "", nav: "" } },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c("LeaveDialog", {
                                    attrs: {
                                      levelLeavingTitle:
                                        _vm.currentSpaceType ===
                                        _vm.spaceTypes.EDUCATION_SPACE
                                          ? "leave course"
                                          : _vm.currentSpaceType ===
                                            _vm.spaceTypes.RESEARCH_SPACE
                                          ? "leave project"
                                          : "leave space",
                                      hierarchicalLevel:
                                        _vm.nuvolosObjectTypes.SPACE,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.detailsDrawer,
            callback: function ($$v) {
              _vm.detailsDrawer = $$v
            },
            expression: "detailsDrawer",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center pa-3" },
            [
              _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE
                ? _c(
                    "span",
                    {
                      staticClass:
                        "secondary--text subtitle-2 font-weight-medium",
                    },
                    [_vm._v("Course details")]
                  )
                : _vm.currentSpaceType === _vm.spaceTypes.RESEARCH_SPACE
                ? _c(
                    "span",
                    {
                      staticClass:
                        "secondary--text subtitle-2 font-weight-medium",
                    },
                    [_vm._v("Project details")]
                  )
                : _vm.currentSpaceType === _vm.spaceTypes.VENDOR_SPACE
                ? _c(
                    "span",
                    {
                      staticClass:
                        "secondary--text subtitle-2 font-weight-medium",
                    },
                    [_vm._v("Dataset details")]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.detailsDrawer = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE &&
          _vm.isMasterInstance &&
          _vm.isInstanceEditor
            ? _c(
                "v-alert",
                { attrs: { type: "info", text: "", prominent: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(
                          "Material in the master instance is not directly visible to students, it has to be distributed."
                        ),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                href: "https://docs.nuvolos.cloud/getting-started/nuvolos-basic-concepts/distribution#distributing-teaching-material-to-students",
                                target: "_blank",
                                small: "",
                                color: "info",
                              },
                            },
                            [_vm._v(" Learn more ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Visibility")])],
                1
              ),
              _c("v-col", [
                _vm.currentSpaceVisibility ===
                _vm.spaceVisibilityCodes.PUBLIC_SPACE
                  ? _c("span", { staticClass: "subtitle-2 secondary--text" }, [
                      _vm._v("Public"),
                    ])
                  : _vm._e(),
                _vm.currentSpaceVisibility ===
                _vm.spaceVisibilityCodes.PRIVATE_SPACE
                  ? _c("span", { staticClass: "subtitle-2 secondary--text" }, [
                      _vm._v("Private"),
                    ])
                  : _vm._e(),
                _vm.currentSpaceVisibility ===
                _vm.spaceVisibilityCodes.FACULTY_ONLY_SPACE
                  ? _c("span", { staticClass: "subtitle-2 secondary--text" }, [
                      _vm._v("Faculty only"),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Lifecycle phase")])],
                1
              ),
              _c("v-col", [
                !_vm.currentSpaceArchived
                  ? _c("span", { staticClass: "subtitle-2 secondary--text" }, [
                      _vm._v("Live"),
                    ])
                  : _vm._e(),
                _vm.currentSpaceArchived
                  ? _c("span", { staticClass: "subtitle-2 secondary--text" }, [
                      _vm._v(
                        "Archived on " +
                          _vm._s(
                            _vm._f("dateTimeToHuman")(_vm.spaceArchiveTimestamp)
                          )
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm.isSpaceAdmin
            ? _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [_c("v-subheader", [_vm._v("Space role")])],
                    1
                  ),
                  _c("v-col", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-center subtitle-2 secondary--text",
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { small: "", color: "secondary" } },
                          [_vm._v("security")]
                        ),
                        _vm._v(" Administrator "),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Instance name")])],
                1
              ),
              _c(
                "v-col",
                [
                  _vm.isInstanceEditor
                    ? _c("EditableArea", {
                        attrs: {
                          value: _vm.instanceLongName,
                          apiURL: "/instances/" + this.$route.params.iid,
                          successMessage: "Instance name successfully updated!",
                          editAttr: "long_id",
                          editAttrFullName: "instance name",
                          defaultValue: "Instance name not available",
                          color: "white",
                          showEditIcon:
                            _vm.isInstanceEditor &&
                            _vm.canDeleteInstanceOrEditFields,
                          id: parseInt(_vm.$route.params.sid, 10),
                          finally: () => {
                            this.$store.dispatch(
                              "spaceStore/fetchSpaceInstances",
                              this.$route.params.sid
                            )
                          },
                        },
                      })
                    : !_vm.isInstanceEditor
                    ? _c(
                        "span",
                        { staticClass: "secondary--text subtitle-2" },
                        [_vm._v(_vm._s(_vm.instanceName))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Instance description")])],
                1
              ),
              _c(
                "v-col",
                [
                  _vm.isInstanceEditor
                    ? _c("EditableArea", {
                        attrs: {
                          value: _vm.currentInstanceDescription,
                          apiURL: "/instances/" + this.$route.params.iid,
                          successMessage:
                            "Instance description successfully updated!",
                          editAttr: "description",
                          editAttrFullName: "instance description",
                          defaultValue: "Instance description not available",
                          color: "white",
                          showEditIcon:
                            _vm.isInstanceEditor &&
                            _vm.canDeleteInstanceOrEditFields,
                          id: parseInt(_vm.$route.params.sid, 10),
                          finally: () => {
                            this.$store.dispatch(
                              "spaceStore/fetchSpaceInstances",
                              this.$route.params.sid
                            )
                          },
                        },
                      })
                    : !_vm.isInstanceEditor
                    ? _c(
                        "span",
                        { staticClass: "secondary--text subtitle-2" },
                        [_vm._v(_vm._s(_vm.currentInstanceDescription))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Archive by date")])],
                1
              ),
              _c(
                "v-col",
                [
                  _vm.isInstanceEditor
                    ? _c("EditableArea", {
                        attrs: {
                          value: _vm.spaceArchivalDate,
                          type: "date",
                          max: _vm.furthestArchivalDate,
                          apiURL: "/spaces/" + this.$route.params.sid,
                          successMessage: "Archival date successfully updated!",
                          editAttr: "archive_by_date",
                          editAttrFullName: "date of archival",
                          defaultValue: "9999-12-31",
                          color: "white",
                          showEditIcon:
                            _vm.isSpaceAdmin && !_vm.currentSpaceArchived,
                          id: parseInt(_vm.$route.params.sid, 10),
                          finally: () => {
                            this.$store.dispatch(
                              "orgStore/fetchOrgSpaces",
                              this.$route.params.oid
                            )
                          },
                        },
                      })
                    : !_vm.isInstanceEditor
                    ? _c(
                        "span",
                        { staticClass: "secondary--text subtitle-2" },
                        [_vm._v(_vm._s(_vm.spaceArchivalDate))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }